"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COORD_RIGHT_HALF_MOON_FRAME = exports.COORD_LEFT_HALF_MOON_FRAME = exports.COORD_RIGHT_EYE_HALF_MOON_2 = exports.COORD_RIGHT_EYE_HALF_MOON_1 = exports.COORD_LEFT_EYE_HALF_MOON_2 = exports.COORD_LEFT_EYE_HALF_MOON_1 = exports.COORD_EAR_FRAME = exports.COORD_BRIDGE_FRAME = exports.COORD_RIGHT_FRAME = exports.COORD_LEFT_FRAME = exports.COORD_BLANK = exports.PARAMS_EAR = exports.PARAMS_BRIDGE = exports.PARAMS_PATH = exports.PARAMS_CIRCLE = void 0;
exports.PARAMS_CIRCLE = {
    r: 3,
    cx_left: 12,
    cx_right: 19,
    cy: 14,
};
exports.PARAMS_PATH = {
    left: 12,
    top: 12,
    right: 19,
    bottom: 16,
};
exports.PARAMS_BRIDGE = {
    w: 13,
    h: 1,
    x: 12,
    y: 13,
};
exports.PARAMS_EAR = {
    w: 1,
    h: 2,
    x: 24,
    y: 14,
};
exports.COORD_BLANK = [
    [8, 11],
    [9, 11],
    [14, 11],
    [15, 11],
    [16, 11],
    [21, 11],
    [22, 11],
    [23, 11],
    [24, 11],
    [8, 12],
    [15, 12],
    [22, 12],
    [23, 12],
    [24, 12],
    [8, 13],
    [8, 14],
    [15, 14],
    [22, 14],
    [23, 14],
    [8, 15],
    [15, 15],
    [22, 15],
    [23, 15],
    [8, 16],
    [9, 16],
    [14, 16],
    [15, 16],
    [16, 16],
    [21, 16],
    [22, 16],
    [23, 16],
    [24, 16],
];
exports.COORD_LEFT_FRAME = [
    [10, 11],
    [11, 11],
    [12, 11],
    [13, 11],
    [9, 12],
    [14, 12],
    [9, 13],
    [14, 13],
    [9, 14],
    [14, 14],
    [9, 15],
    [14, 15],
    [10, 16],
    [11, 16],
    [12, 16],
    [13, 16],
];
exports.COORD_RIGHT_FRAME = [
    [17, 11],
    [18, 11],
    [19, 11],
    [20, 11],
    [16, 12],
    [21, 12],
    [16, 13],
    [21, 13],
    [16, 14],
    [21, 14],
    [16, 15],
    [21, 15],
    [17, 16],
    [18, 16],
    [19, 16],
    [20, 16],
];
exports.COORD_BRIDGE_FRAME = [
    [15, 13],
    [22, 13],
    [23, 13],
    [24, 13],
];
exports.COORD_EAR_FRAME = [
    [24, 14],
    [24, 15],
];
exports.COORD_LEFT_EYE_HALF_MOON_1 = [
    [11, 12],
    [10, 13],
    [11, 13],
    [10, 14],
    [11, 14],
    [11, 15],
];
exports.COORD_LEFT_EYE_HALF_MOON_2 = [
    [12, 12],
    [12, 13],
    [13, 13],
    [12, 14],
    [13, 14],
    [12, 15],
];
exports.COORD_RIGHT_EYE_HALF_MOON_1 = [
    [18, 12],
    [17, 13],
    [18, 13],
    [17, 14],
    [18, 14],
    [18, 15],
];
exports.COORD_RIGHT_EYE_HALF_MOON_2 = [
    [19, 12],
    [19, 13],
    [20, 13],
    [19, 14],
    [20, 14],
    [19, 15],
];
exports.COORD_LEFT_HALF_MOON_FRAME = [
    [10, 11],
    [11, 11],
    [12, 11],
    [13, 11],
    [9, 12],
    [14, 12],
    [9, 13],
    [14, 13],
    [9, 14],
    [14, 14],
    [9, 15],
    [14, 15],
    [10, 16],
    [11, 16],
    [12, 16],
    [13, 16],
    [10, 12],
    [13, 12],
    [10, 15],
    [13, 15],
];
exports.COORD_RIGHT_HALF_MOON_FRAME = [
    [17, 11],
    [18, 11],
    [19, 11],
    [20, 11],
    [16, 12],
    [21, 12],
    [16, 13],
    [21, 13],
    [16, 14],
    [21, 14],
    [16, 15],
    [21, 15],
    [17, 16],
    [18, 16],
    [19, 16],
    [20, 16],
    [17, 12],
    [20, 12],
    [17, 15],
    [20, 15],
];
