"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSingleColor = exports.getHexColorAt = exports.rgbToHex = exports.toPaddedHex = void 0;
/**
 * Convert the provided number to a passed hex string
 * @param c
 * @param pad The desired number of chars in the hex string
 */
const toPaddedHex = (c, pad = 2) => {
    return c.toString(16).padStart(pad, '0');
};
exports.toPaddedHex = toPaddedHex;
/**
 * Convert an RGB color to hex (without `#` prefix)
 * @param r The red value
 * @param g The green value
 * @param b The blue value
 */
const rgbToHex = (r, g, b) => {
    return `${(0, exports.toPaddedHex)(r)}${(0, exports.toPaddedHex)(g)}${(0, exports.toPaddedHex)(b)}`;
};
exports.rgbToHex = rgbToHex;
/**
 * Fetch the hex color of a coordinate given a getRgbaAt function
 * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
 * @param x x-coordinate of image
 * @param y y-coordinate of image
 */
const getHexColorAt = (getRgbaAt, x, y) => {
    const { r, g, b, a } = getRgbaAt(x, y);
    if (a === 0)
        return `00FFFFFF`;
    return `${(0, exports.toPaddedHex)(r)}${(0, exports.toPaddedHex)(g)}${(0, exports.toPaddedHex)(b)}`;
};
exports.getHexColorAt = getHexColorAt;
/**
 * Check whether the given list of pixels have the same color
 * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
 * @param coordinates list of [x, y] coordinates
 */
const isSingleColor = (getRgbaAt, coordinates) => {
    const [x0, y0] = coordinates[0];
    const initColor = (0, exports.getHexColorAt)(getRgbaAt, x0, y0);
    for (const [x, y] of coordinates) {
        const color = (0, exports.getHexColorAt)(getRgbaAt, x, y);
        if (initColor !== color)
            return false;
    }
    return true;
};
exports.isSingleColor = isSingleColor;
