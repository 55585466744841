"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = void 0;
const utils_1 = require("./utils");
const constants_1 = require("./constants");
/**
 * A class used to convert an image into the following RLE format:
 * Palette Index, Bounds [Top (Y), Right (X), Bottom (Y), Left (X)] (4 Bytes), [Pixel Length (1 Byte), Color Index (1 Byte)][].
 */
class Image {
    constructor(width, height) {
        this._rows = {};
        this._bounds = { top: 0, bottom: 0, left: 0, right: 0 };
        this._width = width;
        this._height = height;
    }
    /**
     * The image's pixel width
     */
    get width() {
        return this._width;
    }
    /**
     * The image's pixel height
     */
    get height() {
        return this._height;
    }
    /**
     * The number of rows to run-length encode
     */
    get rows() {
        return this._rows;
    }
    /**
     * The bounds of the inner rect to run-length encode
     */
    get bounds() {
        return this._bounds;
    }
    /**
     * Convert an image to a run-length encoded string using the provided RGBA
     * and color palette values.
     * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
     * @param colors The color palette map
     */
    toRLE(getRgbaAt, colors) {
        if (!this._rle) {
            this._rle = this.encode(getRgbaAt, colors);
        }
        return this._rle;
    }
    /**
     * Convert a glasses image to a run-length encoded string using the provided RGBA
     * and color palette values.
     * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
     * @param colors The color palette map
     */
    toGlassesRLE(getRgbaAt, colors) {
        this.checkValid(getRgbaAt); // checks if its a valid glasses image
        const isHalfMoon = this.checkHalfMoon(getRgbaAt);
        const rle = this.encodeGlasses(getRgbaAt, colors, isHalfMoon);
        return `0x00${rle}`;
    }
    /**
     * Using the image pixel inforation, run-length encode an image.
     * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
     * @param colors The color palette map
     */
    encode(getRgbaAt, colors) {
        var _a;
        for (let y = 0; y < this._height; y++) {
            for (let x = 0; x < this._width; x++) {
                const { r, g, b, a } = getRgbaAt(x, y);
                const hexColor = (0, utils_1.rgbToHex)(r, g, b);
                // Insert the color if it does not yet exist
                if (!colors.has(hexColor)) {
                    colors.set(hexColor, colors.size);
                }
                // If alpha is 0, use 'transparent' index, otherwise get color index
                const colorIndex = a === 0 ? 0 : colors.get(hexColor);
                this.appendPixelToRect(colorIndex, y);
            }
            this.updateImageBounds(y);
        }
        this.deleteEmptyRows();
        // Set the left and right bounds. Return early if empty
        const rowCount = Object.keys(this._rows).length;
        if (rowCount) {
            this._bounds.left = Math.min(...Object.values(this._rows).map(r => r.bounds.left));
            this._bounds.right = Math.max(...Object.values(this._rows).map(r => r.bounds.right));
            // Exit early if image is empty
            const [rect] = ((_a = this._rows[0]) === null || _a === void 0 ? void 0 : _a.rects) || [];
            if (rowCount === 1 && this.isEmptyRow(rect)) {
                return '0x0000000000';
            }
        }
        const encodedBounds = this.getEncodedBounds(this._bounds);
        const encodedImage = Object.values(this._rows).reduce((result, row) => {
            result += this.getEncodedRow(row, this._bounds);
            return result;
        }, encodedBounds);
        return encodedImage;
    }
    /**
     * Append a single pixel to a new or existing rect
     * @param colorIndex The color array index
     * @param y The current `y` coordinate
     */
    appendPixelToRect(colorIndex, y) {
        var _a;
        // Create the row if it does not exist yet
        const { rects } = ((_a = this._rows)[y] || (_a[y] = {
            rects: [],
            bounds: { left: 0, right: 0 },
        }));
        // First pixel of line or different color than previous
        if (!rects.length || rects[rects.length - 1].colorIndex !== colorIndex) {
            rects.push({ length: 1, colorIndex });
            return;
        }
        // Same color as the pixel to the left
        rects[rects.length - 1].length++;
    }
    /**
     * Update the bounds of the provided image
     * @param y The current `y` coordinate
     */
    updateImageBounds(y) {
        const { rects } = this._rows[y];
        // Shift top bound to `y` if row is not empty and top bound is 0
        if (!this.isEmptyRow(rects[0]) && this._bounds.top === 0) {
            this._bounds.top = y;
        }
        if (this._bounds.top !== 0) {
            // Set bottom bound to `y` if row is empty or we're on the last row.
            // Otherwise, reset the bottom bound
            if (this.isEmptyRow(rects[0])) {
                if (this._bounds.bottom === 0) {
                    this._bounds.bottom = y - 1;
                }
            }
            else if (y === 31) {
                this._bounds.bottom = y;
            }
            else {
                this._bounds.bottom = 0;
            }
        }
        this._rows[y].bounds = {
            left: rects[0].length,
            right: this._width - rects[rects.length - 1].length,
        };
    }
    /**
     * Delete all empty rows. That is, all rows above the top bound or
     * below the lower bound
     */
    deleteEmptyRows() {
        // Delete all rows above the top bound
        for (let i = 0; i < this._bounds.top; i++) {
            delete this._rows[i];
        }
        // Delete all rows below the bottom bound
        for (let i = this._height - 1; i > this._bounds.bottom; i--) {
            delete this._rows[i];
        }
    }
    /**
     * Get the encoded part bounds string
     * @param bounds The part bounds
     */
    getEncodedBounds(bounds) {
        const top = (0, utils_1.toPaddedHex)(bounds.top, 2);
        const right = (0, utils_1.toPaddedHex)(bounds.right, 2);
        const bottom = (0, utils_1.toPaddedHex)(bounds.bottom, 2);
        const left = (0, utils_1.toPaddedHex)(bounds.left, 2);
        return `0x00${top}${right}${bottom}${left}`;
    }
    /**
     * Get a single row encoded as a hex string
     * @param row The row data
     * @param bounds The image bounds
     */
    getEncodedRow(row, bounds) {
        const rowBuffer = Buffer.from(row.rects.flatMap(({ length, colorIndex }, i) => {
            // Row only contains a single rect
            if (i === 0 && i === row.rects.length - 1) {
                return [bounds.right - bounds.left, colorIndex];
            }
            // Set left bound
            if (i === 0) {
                if (length > bounds.left) {
                    return [length - bounds.left, colorIndex];
                }
                else if (length === bounds.left) {
                    return [];
                }
            }
            // Set right bound
            if (i === row.rects.length - 1) {
                if (length > this._width - bounds.right) {
                    return [length - (this._width - bounds.right), colorIndex];
                }
                else if (length === this._width - bounds.right) {
                    return [];
                }
            }
            return [length, colorIndex];
        }));
        return rowBuffer.toString('hex');
    }
    /**
     * Determine if the provided rect fills the entire row and is transparent
     * @param rect The rect to inspect
     */
    isEmptyRow(rect) {
        return (rect === null || rect === void 0 ? void 0 : rect.length) === this._width && (rect === null || rect === void 0 ? void 0 : rect.colorIndex) === 0;
    }
    /**
     * Using the image pixel information, run-length encode a glasses image.
     * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
     * @param colors The color palette map
     * @param isHalfMoon Boolean to indicate whether or not they are half-moon glasses
     */
    encodeGlasses(getRgbaAt, colors, isHalfMoon) {
        const rleArray = [];
        isHalfMoon ? rleArray.push(1) : rleArray.push(0);
        const traitColors = {
            bridgeFrame: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_BRIDGE_FRAME[0][0], constants_1.COORD_BRIDGE_FRAME[0][1]),
            earFrame: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_EAR_FRAME[0][0], constants_1.COORD_EAR_FRAME[0][1]),
            leftFrame: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_LEFT_FRAME[0][0], constants_1.COORD_LEFT_FRAME[0][1]),
            rightFrame: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_RIGHT_FRAME[0][0], constants_1.COORD_RIGHT_FRAME[0][1]),
            leftEyeHalfMoon1: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_LEFT_EYE_HALF_MOON_1[0][0], constants_1.COORD_LEFT_EYE_HALF_MOON_1[0][1]),
            leftEyeHalfMoon2: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_LEFT_EYE_HALF_MOON_2[0][0], constants_1.COORD_LEFT_EYE_HALF_MOON_2[0][1]),
            rightEyeHalfMoon1: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_RIGHT_EYE_HALF_MOON_1[0][0], constants_1.COORD_RIGHT_EYE_HALF_MOON_1[0][1]),
            rightEyeHalfMoon2: (0, utils_1.getHexColorAt)(getRgbaAt, constants_1.COORD_RIGHT_EYE_HALF_MOON_2[0][0], constants_1.COORD_RIGHT_EYE_HALF_MOON_2[0][1]),
        };
        const svgParams = {
            bridgeFrame: [...Object.values(constants_1.PARAMS_BRIDGE)],
            earFrame: [...Object.values(constants_1.PARAMS_EAR)],
            leftFrame: [constants_1.PARAMS_CIRCLE.r, constants_1.PARAMS_CIRCLE.cx_left, constants_1.PARAMS_CIRCLE.cy, 0],
            rightFrame: [constants_1.PARAMS_CIRCLE.r, constants_1.PARAMS_CIRCLE.cx_right, constants_1.PARAMS_CIRCLE.cy, 0],
            leftEyeHalfMoon1: [constants_1.PARAMS_PATH.left, constants_1.PARAMS_PATH.bottom, constants_1.PARAMS_PATH.left, constants_1.PARAMS_PATH.top],
            leftEyeHalfMoon2: [constants_1.PARAMS_PATH.left, constants_1.PARAMS_PATH.top, constants_1.PARAMS_PATH.left, constants_1.PARAMS_PATH.bottom],
            rightEyeHalfMoon1: [
                constants_1.PARAMS_PATH.right,
                constants_1.PARAMS_PATH.bottom,
                constants_1.PARAMS_PATH.right,
                constants_1.PARAMS_PATH.top,
            ],
            rightEyeHalfMoon2: [
                constants_1.PARAMS_PATH.right,
                constants_1.PARAMS_PATH.top,
                constants_1.PARAMS_PATH.right,
                constants_1.PARAMS_PATH.bottom,
            ],
        };
        const flatTraitColors = isHalfMoon
            ? Object.entries(traitColors)
            : Object.entries(traitColors).slice(0, 4);
        for (const [trait, color] of flatTraitColors) {
            if (!colors.has(color)) {
                colors.set(color, colors.size);
            }
            const colorIndex = color === '00FFFFFF' ? 0 : colors.get(color);
            rleArray.push(colorIndex);
            const params = svgParams[trait];
            params === null || params === void 0 ? void 0 : params.forEach(value => {
                rleArray.push(value);
            });
        }
        if (!isHalfMoon) {
            for (let y = 12; y < 15; y++) {
                for (let x = 10; x < 21; x++) {
                    const hexColor = (0, utils_1.getHexColorAt)(getRgbaAt, x, y);
                    if ((x < 14 && hexColor !== traitColors.leftFrame) ||
                        (x >= 17 && hexColor !== traitColors.rightFrame)) {
                        if (!colors.has(hexColor)) {
                            colors.set(hexColor, colors.size);
                        }
                        const colorIndex = colors.get(hexColor);
                        rleArray.push(colorIndex);
                        rleArray.push(1, 1, x, y);
                    }
                }
            }
        }
        const buffer = Buffer.from(rleArray);
        const rle = buffer.toString('hex');
        return rle;
    }
    /**
     * Check whether given image depicts half-moon glasses or not
     * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
     */
    checkHalfMoon(getRgbaAt) {
        if (!(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_LEFT_HALF_MOON_FRAME) ||
            !(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_RIGHT_HALF_MOON_FRAME) ||
            !(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_LEFT_EYE_HALF_MOON_1) ||
            !(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_RIGHT_EYE_HALF_MOON_1) ||
            !(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_LEFT_EYE_HALF_MOON_2) ||
            !(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_RIGHT_EYE_HALF_MOON_2))
            return false;
        return true;
    }
    /**
     * Check whether given image is a valid glasses image
     * @param getRgbaAt A function used to fetch the RGBA values at specific x-y coordinates
     */
    checkValid(getRgbaAt) {
        for (const [x, y] of constants_1.COORD_BLANK) {
            const { a } = getRgbaAt(x, y);
            if (a !== 0)
                throw new Error('Invalid pixels. Please check that you are conforming to the SZNouns glasses.png  standard.');
        }
        if (!(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_LEFT_FRAME))
            throw new Error('Invalid left eye frame coloring. All pixels on the left eye frame of the glasses image must be of the same color. Check the constants.ts file for the relevant pixel coordinates.');
        if (!(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_RIGHT_FRAME))
            throw new Error('Invalid right eye frame coloring. All pixels on the right eye frame of the glasses image must be of the same color. Check the constants.ts file for the relevant pixel coordinates.');
        if (!(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_BRIDGE_FRAME))
            throw new Error('Invalid bridge frame coloring. All pixels on the bridge frame of the glasses image must be of the same color. Check the constants.ts file for the relevant pixel coordinates.');
        if (!(0, utils_1.isSingleColor)(getRgbaAt, constants_1.COORD_EAR_FRAME))
            throw new Error('Invalid ear frame coloring. All pixels on the ear frame of the glasses image must be of the same color. Check the constants.ts file for the relevant pixel coordinates.');
    }
}
exports.Image = Image;
